//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Extra from './Extra.vue';

import tableConfig from './tableConfig';

export default {

  components: { Extra },
  props: {
    data: { type: Array, default: () => [] },
  },

  data() {
    return {
      tableConfig,
    };
  },
};
