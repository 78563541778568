var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-extra":""},on:{"update:selection":function($event){_vm.selection=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"data":row}})]}},{key:"amount",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.amount)+" ")]}},{key:"currency",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.currency)+" ")]}},{key:"created",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(row.created * 1000))+" "),_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$utils.getTime(row.created * 1000)))])]}},{key:"confirmations",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.confirmations || 0)+" / "+_vm._s(row.confirmationsMax)+" ")]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(row.type))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(row.status))+" ")]}},{key:"username",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":+row.serviceMerchantId,"username":row.serviceMerchantName}})]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }