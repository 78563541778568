//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import constant from '~/const/index';
import tableConfig from './tableProductsConfig';
import TransactionsTable from './Transactions/Table.vue';

export default {

  components: { TransactionsTable },
  props: {
    data: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      constant,
      invoice: {},
      transactions: [],
      loading: false,
      isShowProducts: false,
      isShowTransactions: false,
      tableConfig,
    };
  },

  async mounted() {
    this.setGeneralProgress(true);
    this.loading = true;
    this.invoice = await this.getInvoice(this.data.id);
    this.setGeneralProgress(false);
    this.loading = false;
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
    ]),
    ...mapActions('merchantsInvoices', ['getInvoice', 'getTransactions']),

    showProducts() {
      this.isShowProducts = true;
    },

    async showTransactions() {
      this.setGeneralProgress(true);
      const transactions = await this.getTransactions(this.data.id);
      this.transactions = transactions?.items ?? [];
      this.setGeneralProgress(false);
      this.isShowTransactions = true;
    },
  },
};
